<template>
   <div>
        <input
            type="file"
            hidden
            accept="image/*"
            ref="fileInput"
            @change="handleFileChange"
        />
    <v-container
      fluid
      v-if="isModal"
    >
      <Modal
        :modal="isModal"
        width="650"
        persistent
      >
          <v-card class="pa-5 pt-0 confirm-popup" flat>
            <v-card-title class="d-flex pl-0 pr-0">
              <v-row class="my-0 mr-0 ml-0">
                <v-col class="d-flex align-center py-0 pl-0">
                  <v-card-title class="pa-0 crop-title">Crop Profile Photo</v-card-title>
                </v-col>
                <v-col class="d-flex justify-end pa-0">
                  <v-icon color="darken-1" @click="closeModal">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider/>
            <div class="cropper-container">
              <cropper
                ref="cropper"
                class="cropper"
                :height="450"
                :src="imageURL"
                :stencil-props="{
                  aspectRatio: 1/1
                }"
                @change="doCrop"
                :min-width="300"
                :min-height="300"
              />
            </div>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                class="ml-2 mt-7 bottom-margin submit"
                color="info"
                @click="save"> Save </v-btn>
              <v-btn
                class="ml-2 mt-7 bottom-margin cancel"
                @click="close"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
      </Modal>
    </v-container>
   </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Modal from '@/components/common/Modal';

export default {
  name: 'ProfilePicCropper',
  components: {
    Modal,
    Cropper,
  },
  data() {
    return ({
      canvas: null,
      resolve: null,
      reject: null,
      file: null,
      imageURL: null,
      isModal: false,
    });
  },
  methods: {
    doCrop({ canvas }) {
      this.canvas = canvas.toDataURL();
    },
    getCroppedFile() {
      const url = this.canvas;
      const byteString = atob(url.split(',')[1]);
      const mimeString = url.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new File([ab], this.file.name, { type: mimeString });
    },
    open() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
        this.$refs.fileInput.click();
      });
    },
    handleFileChange(e) {
      const file = e.target.files[0];
      this.file = file;
      this.imageURL = URL.createObjectURL(file);
      this.isModal = true;
    },
    close() {
      this.file = null;
      this.isModal = false;
      this.reject(null);
    },
    save() {
      const fileData = this.getCroppedFile();
      this.isModal = false;
      this.file = null;
      this.resolve(fileData);
    },

  },
};
</script>

<style lang="scss" scoped>
    .cropper-container{
      height: 450px;
    }
    .cropper {
      height: 100%;
      background: #DDD;
    }
</style>
