import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{ref:"fileInput",attrs:{"type":"file","hidden":"","accept":"image/*"},on:{"change":_vm.handleFileChange}}),(_vm.isModal)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"modal":_vm.isModal,"width":"650","persistent":""}},[_c(VCard,{staticClass:"pa-5 pt-0 confirm-popup",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex pl-0 pr-0"},[_c(VRow,{staticClass:"my-0 mr-0 ml-0"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0"},[_c(VCardTitle,{staticClass:"pa-0 crop-title"},[_vm._v("Crop Profile Photo")])],1),_c(VCol,{staticClass:"d-flex justify-end pa-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":_vm.closeModal}},[_vm._v("mdi-close")])],1)],1)],1),_c(VDivider),_c('div',{staticClass:"cropper-container"},[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"height":450,"src":_vm.imageURL,"stencil-props":{
               aspectRatio: 1/1
             },"min-width":300,"min-height":300},on:{"change":_vm.doCrop}})],1),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"ml-2 mt-7 bottom-margin submit",attrs:{"color":"info"},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c(VBtn,{staticClass:"ml-2 mt-7 bottom-margin cancel",on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }